import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {SeatingMode} from '../../types'
import {setPlaceQuantity} from '../../actions/seating/place'

const initialState: SeatingMode = {
  accessibility: false,
  mobileBasket: false,
  selectedPlaceId: null,
  showDescription: false,
  descriptionPlace: null,
  showPricingOptionsPlace: null,
}

const modeSlice = createSlice({
  name: 'mode',
  initialState,
  reducers: {
    setShowAccessibilityMode(state, action: PayloadAction<boolean>) {
      state.accessibility = action.payload
    },
    setShowMobileBasket(state, action: PayloadAction<boolean>) {
      state.mobileBasket = action.payload
    },
    setShowPricingOptionsPlace(state, action: PayloadAction<PlaceWithTicketInfo>) {
      state.showPricingOptionsPlace = action.payload
    },
    setShowPlaceDescription(state, action: PayloadAction<PlaceWithTicketInfo>) {
      state.showDescription = Boolean(action.payload)
      if (state.showDescription) {
        state.descriptionPlace = action.payload
      }
    },
    selectPlace(state, action: PayloadAction<string>) {
      state.selectedPlaceId = action.payload
    },
    unselectPlace(state) {
      state.selectedPlaceId = null
    },
  },
  extraReducers: builder => {
    builder.addCase(setPlaceQuantity.fulfilled, state => {
      state.selectedPlaceId = null
    })
  },
})

export const {
  setShowPricingOptionsPlace,
  setShowMobileBasket,
  setShowAccessibilityMode,
  setShowPlaceDescription,
  selectPlace,
  unselectPlace,
} = modeSlice.actions
export default modeSlice.reducer
