import {AnyAction} from 'redux'
import {HttpError} from '@wix/http-client'
import {clearReservationError, RESERVE_TICKETS, cancelReservation} from '../actions/reservation'
import {Reservation} from '../types'

export enum RESERVATION_ERROR {
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  PLACE_RESERVED = 'PLACE_RESERVED',
}

const defaultState: Reservation = {
  data: null,
  error: null,
}

export const reservation = (state = defaultState, action: AnyAction): Reservation => {
  switch (action.type) {
    case RESERVE_TICKETS.SUCCESS:
      return {...state, error: null, data: action.payload}
    case cancelReservation.fulfilled.toString():
      return {...state, error: null, data: null}
    case RESERVE_TICKETS.FAILURE:
      const error: HttpError = action.error
      return {
        ...state,
        data: null,
        error: error?.response?.data?.details?.applicationError?.code ?? RESERVATION_ERROR.SOMETHING_WENT_WRONG,
      }
    case clearReservationError.toString():
      return {...state, error: null}
    default:
      return state
  }
}
