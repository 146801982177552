import {createAsyncThunk} from '@reduxjs/toolkit'
import {EventsMembersPageState} from '../types'
import {ThunkConfig} from './interfaces'

export const getEventsMembersPageInfo = createAsyncThunk<EventsMembersPageState, void, ThunkConfig>(
  'GET_EVENTS_MEMBERS_PAGE_INFO',
  async (_, {extra: {wixCodeApi, serverApi}}) => {
    const siteStructure = await wixCodeApi.site.getSiteStructure({includePageId: true})
    const data = await serverApi.getEventsMembersPage()
    return siteStructure?.pages?.find(page => page?.id === data?.pageId)
  },
)
