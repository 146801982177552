import {createReducer} from '@reduxjs/toolkit'
import {PaidPlansState} from '../types'
import {getPlanList, setSelectedPlanOrderId} from '../actions/paid-plans'
import {getSortedPlanList, getBestNonExpiredPlan} from '../selectors/paid-plans'

const defaultState: PaidPlansState = {
  planList: [],
  purchasedPlanList: [],
  selectedPaidPlanOrderId: null,
  translatedDetails: {},
}

export const paidPlans = createReducer(defaultState, builder => {
  builder
    .addCase(getPlanList.fulfilled, (state, action) => {
      const planList = getSortedPlanList(action.payload.plans)
      const purchasedPlanList = planList.filter(plan => plan.purchased)

      return {
        ...state,
        planList,
        purchasedPlanList,
        selectedPaidPlanOrderId: getBestNonExpiredPlan(purchasedPlanList)?.planOrderId ?? null,
        translatedDetails: action.payload.translatedDetails,
      }
    })
    .addCase(setSelectedPlanOrderId, (state, action) => ({
      ...state,
      selectedPaidPlanOrderId: action.payload,
    }))
})
