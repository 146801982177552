import {createReducer} from '@reduxjs/toolkit'
import {CHANGE_EVENT} from '../actions/event'
import {selectTicket, changeTicketDonation, setDonationError} from '../actions/selected-tickets'
import {SelectedTickets} from '../types'

const defaultState: SelectedTickets = {}

export const selectedTickets = createReducer(defaultState, builder => {
  builder
    .addCase(selectTicket.fulfilled, (state, {payload: {ticketId, count, pricingOptionId}}) => {
      let pricingOptionIds = state[ticketId]?.pricingOptionIds
      let quantity = count

      if (pricingOptionId) {
        pricingOptionIds = (pricingOptionIds ?? [])
          .filter(id => id !== pricingOptionId)
          .concat(...Array(count).fill(pricingOptionId))
        quantity = pricingOptionIds.length
      }

      return {
        ...state,
        [ticketId]: {
          ...state[ticketId],
          quantity,
          pricingOptionIds,
          donation: quantity ? state[ticketId]?.donation : undefined,
        },
      }
    })
    .addCase(changeTicketDonation.fulfilled, (state, {payload: {ticketId, donation, quantity}}) => ({
      ...state,
      [ticketId]: {
        ...state[ticketId],
        donation,
        quantity,
        donationError: undefined,
      },
    }))
    .addCase(setDonationError, (state, {payload: {ticketId, error}}) => ({
      ...state,
      [ticketId]: {
        ...state[ticketId],
        donationError: error,
      },
    }))
    .addCase(CHANGE_EVENT, () => defaultState)
})
