import {AnyAction} from 'redux'
import {GET_CHECKOUT_OPTIONS} from '../actions/checkout-options'
import {CheckoutOptionsState} from '../types'

const defaultState: CheckoutOptionsState = {
  loaded: false,
  options: {
    paymentMethodConfigured: false,
    acceptCoupons: false,
    premiumServices: true,
  },
}

export const checkoutOptions = (state = defaultState, action: AnyAction): CheckoutOptionsState => {
  switch (action.type) {
    case GET_CHECKOUT_OPTIONS.SUCCESS:
      return {...state, options: {...state.options, ...action.payload}, loaded: true}
    default:
      return state
  }
}
