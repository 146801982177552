import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PlaceWithTicketInfo, hasPricingOptions} from '@wix/wix-events-commons-statics'
import {describeSeatingPlan} from '../../actions/seating/plan'

const placesSlice = createSlice({
  name: 'places',
  initialState: [],
  reducers: {
    updatePlace(
      state,
      {
        payload: {place: partialPlace, clearOthers},
      }: PayloadAction<{place: Partial<PlaceWithTicketInfo> & {id: string}; clearOthers?: boolean; origin?: string}>,
    ) {
      return state.map(place => {
        if (place.id === partialPlace.id) {
          return updatePlaceDiff(place, partialPlace)
        } else {
          return clearOthers ? updatePlaceDiff(place, {quantity: 0}) : place
        }
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(describeSeatingPlan.fulfilled, (state, action) => action.payload.places)
  },
})

const updatePlaceDiff = (oldPlace: PlaceWithTicketInfo, diff: Partial<PlaceWithTicketInfo>) => {
  const updatedPlace: PlaceWithTicketInfo = {
    ...oldPlace,
    ...diff,
  }

  if (oldPlace.quantity !== updatedPlace.quantity) {
    if (
      hasPricingOptions(updatedPlace.ticket) &&
      updatedPlace.selectedPricingOptionIds?.length !== updatedPlace.quantity
    ) {
      updatedPlace.selectedPricingOptionIds = new Array(updatedPlace.quantity).fill(
        updatedPlace.pricingOptionId ?? updatedPlace.selectedPricingOptionIds?.[0],
      )
    }
  } else if (oldPlace.selectedPricingOptionIds?.length !== updatedPlace.selectedPricingOptionIds?.length) {
    updatedPlace.quantity = updatedPlace.selectedPricingOptionIds?.length
  }

  if (oldPlace.quantity > 0 && updatedPlace.quantity === 0) {
    updatedPlace.donation = undefined
    updatedPlace.selectedPricingOptionIds = undefined
  }

  if (oldPlace.quantity === 0 && updatedPlace.quantity > 0) {
    updatedPlace.timeAddedToBasket = new Date().getTime()
  }

  return updatedPlace
}

export const {updatePlace} = placesSlice.actions

export default placesSlice.reducer
