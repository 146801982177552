import {combineReducers} from 'redux'
import {SeatingState} from '../../types'
import plan from './plan'
import loading from './loading'
import errors from './errors'
import places from './places'
import filters from './filters'
import mode from './mode'

export const seating = combineReducers<SeatingState>({
  plan,
  loading,
  errors,
  places,
  filters,
  mode,
})
