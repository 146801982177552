import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SeatingFilters} from '../../types'

const initialState: SeatingFilters = {
  selectedPrice: null,
  selectedZone: null,
}

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    selectPrice: (state, action: PayloadAction<string>) => {
      state.selectedPrice = action.payload
    },
    selectLocation: (state, action: PayloadAction<string>) => {
      state.selectedZone = action.payload
    },
    resetFilters: state => {
      state.selectedPrice = null
      state.selectedZone = null
    },
  },
})

export const {selectPrice, selectLocation, resetFilters} = filtersSlice.actions
export default filtersSlice.reducer
