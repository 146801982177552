import {createAction} from '@reduxjs/toolkit'
import {isDonationLessThanMinimum} from '@wix/wix-events-commons-statics'
import {DONATION_ERROR} from '../types'
import {createAsyncAction} from '../services/redux-toolkit'
import {getTicketById, getTickets} from '../selectors/tickets'
import {getSelectedTicketDonation, getSelectedTicketQuantity, isTicketLimitReached} from '../selectors/selected-tickets'
import {checkDonationError, isEmptyDonation} from '../services/donations'

export interface SelectTicketRequest {
  ticketId: string
  count: number
  pricingOptionId?: string
}

export interface ChangeTicketDonation {
  ticketId: string
  donation: string
}

export interface ChangeTicketDonationResponse {
  ticketId: string
  donation: string
  quantity: number
}

export interface SetDonationError {
  ticketId: string
  error: DONATION_ERROR
}

export const selectTicket = createAsyncAction<SelectTicketRequest, SelectTicketRequest>(
  'SELECT_TICKET',
  async ({ticketId, count, pricingOptionId}, {getState, dispatch, rejectWithValue}) => {
    const state = getState()
    const currentCount = getSelectedTicketQuantity(state, ticketId, pricingOptionId)
    if (currentCount < count) {
      const ticket = getTicketById(getTickets(state), ticketId)
      const error = checkDonationError(ticket, getSelectedTicketDonation(state, ticketId))
      if (error) {
        dispatch(setDonationError({ticketId, error}))
        return rejectWithValue({error: 'Invalid donation'})
      }
    }
    return {ticketId, count, pricingOptionId}
  },
)

export const changeTicketDonation = createAsyncAction<ChangeTicketDonationResponse, ChangeTicketDonation>(
  'CHANGE_TICKET_DONATION',
  async ({ticketId, donation}, {getState}) => {
    const state = getState()
    const definition = getTicketById(state.tickets, ticketId)
    const selectedQuantity = getSelectedTicketQuantity(state, ticketId)

    let quantity = selectedQuantity
    if (isEmptyDonation(donation) || isDonationLessThanMinimum(definition, Number(donation))) {
      quantity = 0
    } else if (!selectedQuantity && !isTicketLimitReached(state)) {
      quantity = 1
    }

    return {
      ticketId,
      donation,
      quantity,
    }
  },
)

export const setDonationError = createAction<SetDonationError>('SET_DONATION_ERROR')
