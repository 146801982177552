import {updateSiteSettings} from '../../../../commons/actions/site-settings'
import {mergeCompSettings} from '../reducers/component'
import {GetState} from '../types'
import {updateComponentSettings} from './component'

enum SettingsActions {
  UPDATE_COMP_SETTINGS = 'UPDATE_COMP_SETTINGS',
  SITE_SETTINGS = 'SITE_SETTINGS',
}

export const updateSettings = (action: any) => async (dispatch: Function, getState: GetState) => {
  const state = getState()
  switch (action.type) {
    case SettingsActions.UPDATE_COMP_SETTINGS: {
      const newSettings = mergeCompSettings(action.payload, state.component.settings)
      dispatch(updateComponentSettings(newSettings))
      break
    }
    case SettingsActions.SITE_SETTINGS: {
      dispatch(updateSiteSettings(action.settings))
      break
    }
    default: {
      return
    }
  }
}
