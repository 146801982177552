import {createReducer} from '@reduxjs/toolkit'
import {IUser} from '@wix/yoshi-flow-editor'
import {setCurrentUser} from '../actions/current-user'

const initialData: Partial<IUser> = {
  id: null,
  role: null,
  loggedIn: false,
}

export const currentUser = createReducer(initialData, builder => {
  builder.addCase(setCurrentUser, (state, action) => action.payload)
})
